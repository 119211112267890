'use client';
import { Inter } from 'next/font/google';
import { Loader } from '@mantine/core';
import { Suspense } from 'react';
import { Loading, waitWhileAuthenticated } from '@/components/Loading';
import { AuthProvider } from '@/lib/useAuth';

const inter = Inter({ subsets: ['latin'] });

export const dynamic = 'force-dynamic'; // defaults to auto

export default function AuthenticatedLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col items-center gap-4 h-screen w-full justify-center self-center">
          <Loader color="#2CAE4A" size="100" type="bars" />
          <div className="text-center font-bold text-xs text-slate-400">
            Loading...
          </div>
        </div>
      }
    >
      <Loading promise={waitWhileAuthenticated}>
        <AuthProvider>{children}</AuthProvider>
      </Loading>
    </Suspense>
  );
}
