'use client';
import { createContext, useContext, useMemo } from 'react';
import { useLocalStorage } from '@mantine/hooks';

import axios from 'axios';

export const AUTH_CONSTANTS = {
  ACCESS_TOKEN: 'ev-access-token',
  REFRESH_TOKEN: 'ev-refresh-token',
  USER_ID: 'ev-user-id',
};

export interface AuthContextType {
  accessToken: string;
  login: (userName: string, password: string) => Promise<Object>;
  logout: () => Promise<void>;
  refresh: () => Promise<AuthData>;
  getUserId: () => string;
  getAccessToken: () => string;
  isLoggedIn: () => boolean;
}

type AuthData = {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
  refresh_token: string;
  user_id: string;
};

const AuthContext = createContext<AuthContextType>({
  accessToken: '',
  login: async () => undefined!,
  logout: async () => {},
  refresh: async () => undefined!,
  getUserId: () => '',
  getAccessToken: () => '',
  isLoggedIn: () => false,
});

export const loginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<AuthData> => {
  const postReq = await axios.post('/api/v1/login/', {
    email,
    password,
  });

  return postReq.data;
};

const refreshUser = async (refresh_token: string): Promise<AuthData> => {
  const postReq = await axios.post(`/api/v1/refresh/`, {
    refresh_token,
  });

  return postReq.data;
};

export const logoutUser = async (access_token: string) => {
  await axios.post(`/api/v1/logout/`, {
    access_token,
  });
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [accessToken, setAccessToken] = useLocalStorage<string>({
    key: AUTH_CONSTANTS.ACCESS_TOKEN,
    serialize: (val) => val,
    // For some reason, useLocalStorage does not pick up the value immediately.
    // defaultValue: localStorage?.getItem('ev-access-token') || '',
    defaultValue: '',
  });
  const [refreshToken, setRefreshToken] = useLocalStorage<string>({
    key: AUTH_CONSTANTS.REFRESH_TOKEN,
    serialize: (val) => val,
    // defaultValue: localStorage?.getItem('ev-refresh-token') || '',
    defaultValue: '',
  });
  const [userId, setUserId] = useLocalStorage<string>({
    key: AUTH_CONSTANTS.USER_ID,
    serialize: (val) => val,
    // defaultValue: localStorage?.getItem('ev-user-id') || '',
    defaultValue: '',
  });

  const login = async (userName: string, password: string) => {
    const data = await loginUser({ email: userName, password });
    setRefreshToken(data.refresh_token);
    setUserId(data.user_id);
    setAccessToken(data.access_token);
    return data;
  };

  const logout = async () => {
    if (accessToken) {
      await logoutUser(accessToken);
    }
    setRefreshToken('');
    setUserId('');
    setAccessToken('');
    if (typeof window !== 'undefined') {
      window.location.href = '/login';
    }
  };

  const refresh = async () => {
    const data = await refreshUser(refreshToken);
    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    setUserId(data.user_id);
    return data;
  };

  const isLoggedIn = () => {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem(AUTH_CONSTANTS.ACCESS_TOKEN);
      return typeof token !== 'undefined' && token !== '';
    } else {
      return false;
    }
  };

  const getAccessToken = () => accessToken;
  const getUserId = () => userId;

  const value = useMemo(
    () => ({
      accessToken,
      login,
      logout,
      refresh,
      getAccessToken,
      getUserId,
      isLoggedIn,
    }),
    [accessToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
