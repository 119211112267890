import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { logoutUser } from './useAuth';

const terrataAxios = axios.create({
  baseURL: 'api/v1',
});

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = localStorage.getItem('ev-access-token');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
};

const authErrorRetryHandler = async (error: AxiosError) => {
  const originalRequest = error.config!;

  // If the error status is 401 and there is no originalRequest._retry flag,
  // it means the token has expired and we need to refresh it
  //@ts-ignore
  if (error.response.status === 401 && !originalRequest._retry) {
    // @ts-ignore
    originalRequest._retry = true;

    try {
      const refreshToken = localStorage.getItem('ev-refresh-token');
      const response = await axios.post(`/api/v1/refresh/`, {
        refresh_token: refreshToken,
      });
      const { access_token, refresh_token } = response.data;

      localStorage.setItem('ev-access-token', access_token);
      localStorage.setItem('ev-refresh-token', refresh_token);

      // Retry the original request with the new token
      originalRequest.headers.Authorization = `Bearer ${access_token}`;
      return await axios(originalRequest);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      // window.location.href = '/logout';
      if (localStorage.getItem('ev-access-token')) {
        await logoutUser(localStorage.getItem('ev-access-token') || '');
      }
      localStorage.removeItem('ev-access-token');
      localStorage.removeItem('ev-refresh-token');
      localStorage.removeItem('ev-user-id');
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
};

// Add a request interceptor
// @ts-ignore
terrataAxios.interceptors.request.use(authRequestInterceptor, (error) =>
  Promise.reject(error)
);

// Add a response interceptor
// @ts-ignore
terrataAxios.interceptors.response.use(
  (response) => response,
  authErrorRetryHandler
);

export default terrataAxios;
